import { template as template_89e1f6bb58ce4ce2a8b7da9a25d4af3d } from "@ember/template-compiler";
const FKText = template_89e1f6bb58ce4ce2a8b7da9a25d4af3d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
