import { template as template_8a011a1738b34eed948ae7d9fd9a0583 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8a011a1738b34eed948ae7d9fd9a0583(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
